import React, { useCallback, useEffect, useState } from "react";
import ReactSelect, { OptionsOrGroups } from "react-select";
import { useToggle } from "react-use";

import Select from "@/components/Select";
import { inputActions } from "@/components/Autocomplete2/constants";
import { getCleanedAddress } from "../../src/services/suggestions";

const truthy = () => true;

export type OptionType = {
  label: string;
  value: string;
};

export type ExtendedOptionType = OptionType & {
  corrAccount: string;
  bic: string;
  bill: string;
  name: string;
};

type Props = Omit<Parameters<ReactSelect>[0], "inputValue" | "onInputChange"> & {
  error?: string;
  fetcher: (query: string) => Promise<any[]>;
  isDetailedOptions?: boolean;
  required?: boolean;
  readOnly?: boolean;
  optionComponent?: (props: any) => JSX.Element;
  shallow?: boolean;
  smallFontSize?: boolean;
};

const WarehouseAutocomplete = React.forwardRef(
  (
    {
      value,
      onChange,
      onFocus,
      fetcher,
      isDisabled,
      isDetailedOptions,
      required,
      readOnly,
      optionComponent,
      smallFontSize,
      ...props
    }: Props,
    ref,
  ) => {
    const [loading, toggleLoading] = useToggle(false);
    const [showValue, toggleShowValue] = useToggle(true);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<OptionsOrGroups<any, any>[]>([]);
    const [toggle, setToggle] = useState(false);

    const handleInputChange = useCallback(
      (input: any, { action }) => {
        if (action === inputActions.INPUT_BLUR) {
          !value && setInputValue("");
          toggleShowValue(true);

          setToggle(!toggle);
        }
        if (action === inputActions.INPUT_CHANGE || action === inputActions.SET_VALUE) {
          setInputValue(input);
        }
      },
      [value, toggle],
    );

    const handleChange = useCallback(
      async (option: any, meta) => {
        if (!option.isSearchable) {
          onChange && onChange(option, meta);
          setInputValue(option ? option.value : "");
          const response = await getCleanedAddress(option.value);
          console.log(response);
          onChange && onChange(response, meta);
          setInputValue(response?.value ? response.value : "");
          return;
        }
        onChange && onChange(option, meta);
        setInputValue(option ? option.value : "");
      },
      [onChange],
    );
    const handleFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        toggleShowValue(false);
        if (value) {
          setInputValue((value as OptionType).value);
        }
        onFocus && onFocus(event);
      },
      [value, onFocus],
    );

    useEffect(() => {
      if (value === null) {
        setInputValue("");
        return;
      }
    }, [value]);
    useEffect(() => {
      if (isDisabled) return;

      toggleLoading(true);
      fetcher(inputValue).then((result) => {
        setOptions(result);
        toggleLoading(false);
      });
    }, [inputValue, isDisabled]);

    // useEffect(() => {
    //   if (suggestionsValue !== inputValue) {
    //     setInputValue(suggestionsValue);
    //   }
    // }, [toggle]);

    return (
      <>
        <Select
          inputValue={inputValue}
          onInputChange={handleInputChange}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleFocus}
          filterOption={truthy}
          options={options}
          controlShouldRenderValue={showValue && !!value}
          autocomplete
          optionComponent={optionComponent}
          isSearchable
          isLoading={loading}
          isDisabled={isDisabled}
          required={required}
          readOnly={readOnly}
          smallFontSize={smallFontSize}
          {...props}
        />
      </>
    );
  },
);

export default WarehouseAutocomplete;
