import React from "react";

interface Props {
  onClose?: () => void;
  focusOnCloseButton?: boolean;
}

const ModalHeader: React.FC<Props> = ({ focusOnCloseButton, onClose, children }) => (
  <div className="flex w-full flex-row items-start justify-between gap-5">
    <h3 className="text-title1">{children}</h3>
    {onClose && (
      <div className="cursor-pointer" onClick={onClose}>
        <CloseIcon />
      </div>
    )}
  </div>
);

export default ModalHeader;

const CloseIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-disabled hover:stroke-secondaryText active:stroke-primaryText"
  >
    <path d="M18.666 6L6.66602 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.66602 6L18.666 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
