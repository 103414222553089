export const dimensions = {
  borderRadius: "12px",
  containerBorderRadius: "16px",
  height: "56px",
  indicatorMargin: "0 16px 0 0",
  valueContainerMarginLeft: "-1px",
  valueContainerMarginTop: "16px",
  valueContainerPadding: "0 16px",
  inputFontWeight: 600,
  inputFontSize: "16px",
  inputMargin: "0",
};
export const palette = {
  primary: "#E5E9F0",
  opacity25: "#F2F5F9",
  opacity75: "#2196F3",
  bg: "#fff",
  borderColor: "#D0D6E0",
  focusedBorderColor: "#8DA4B4",
};
