import clsx from "clsx";
import React from "react";

const TerminalOption = (props: any) => {
  return (
    <span
      ref={props.innerRef}
      {...props.innerProps}
      className={clsx(
        "flex cursor-pointer flex-col items-start bg-white px-4 py-3 text-textBody hover:bg-bgDisabled active:bg-bg",
        {
          "text-secondaryText/50": props.isSelected,
          "text-primaryText": !props.isSelected,
        },
      )}
    >
      <p className="text-textBodySemi">{props.data.label}</p>
      {!!props.data.addressTerminal && props.data.type !== "unknownSpot" && (
        <p className="text-secondaryText/50">{props.data.addressTerminal}</p>
      )}
    </span>
  );
};

export default TerminalOption;
