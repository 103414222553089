export const DELIVERY_TYPE_SELECT_WRAPPER_TESTID = "delivery-type-select-wrapper";
export const CONTAINER_TYPE_SELECT_WRAPPER_TESTID = "container-type-select-wrapper";

export const CONTAINER_HEIGHT_SELECT_WRAPPER_TESTID = "container-height-select-wrapper";
export const CONTAINER_WEIGHT_SELECT_WRAPPER_TESTID = "container-weight-select-wrapper";

export const PICKUP_CITY_SELECT_WRAPPER_TESTID = "pickup-city-wrapper";
export const PICKUP_TERMINAL_SELECT_WRAPPER_TESTID = "pickup-terminal-wrapper";
export const WAREHOUSE_ADDRESS_WRAPPER_TESTID = "warehouse-address-wrapper";
export const DELIVERY_CITY_SELECT_WRAPPER_TESTID = "delivery-city-wrapper";
export const DELIVERY_TERMINAL_SELECT_WRAPPER_TESTID = "delivery-terminal-wrapper";

export const CALCULATE_TARIFF_BUTTON = "calculate-tariff-button";
export const REGISTER_TO_CALCULATE_TARIFF_BUTTON = "register-to-calculate-tariff-button";
export const TARIFF_CONTAINER_TESTID = "tariff-container";
