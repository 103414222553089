import { useMemo } from "react";

import {
  useContainerClasses,
  useContainerHeights,
  useContainerTypes,
  useDeliveryTypes,
  useHowToCarry,
  useLocations,
  useOrderOptionDictionary,
  useOrderStatuses,
  usePaymentDictionary,
  useTerminals,
} from "@/data/hooks/useOrdersDirectory";
import { containerTypes, EMPTY_CONTAINER, howToCarryOptionsMap } from "@/data/constants";
import { selectActiveOptions } from "../utils";
import { DirectoryRecord, TerminalEntity } from "@/domain";

export function useOrderHowToCarryOptions(containerType?: string) {
  const howToCarryDictionary = useHowToCarry();
  const howToCarryOptions = useMemo(() => {
    if (!containerType) return [];

    const options = howToCarryOptionsMap[containerType] || [];

    return Object.values(howToCarryDictionary).filter((item) => options.indexOf(item.value) !== -1);
  }, [containerType, howToCarryDictionary]);

  return howToCarryOptions;
}

export function useOrderPickupTerminals(deliveryType?: string, pickupCity?: string) {
  const terminalDictionary = useTerminals();
  // терминалы получения
  const pickupTerminals = useMemo(() => {
    return Object.values(terminalDictionary).filter(
      // temporarily remove КП filters
      (t) => t.cityTerminal === pickupCity && t.type !== "unknownSpot",
      // && (deliveryType === LAST_MILE ? t.type === terminals.TERMINAL : true),
    );
  }, [terminalDictionary, deliveryType, pickupCity]);

  return pickupTerminals;
}

export function useOrderDeliveryTerminals(deliveryType?: string, deliveryCity?: string) {
  const terminalDictionary = useTerminals();
  const deliveryTerminals = useMemo(() => {
    return Object.values(terminalDictionary).filter(
      // temporarily remove КП filters
      (t) => t.cityTerminal === deliveryCity,
      // && (deliveryType === FIRST_MILE ? t.type === terminals.TERMINAL : true),
    );
  }, [terminalDictionary, deliveryType, deliveryCity]);
  return deliveryTerminals;
}

export function useOrderOptions(deliveryType?: string, location?: string, containerType?: string) {
  const optionsDictionary = useOrderOptionDictionary();
  return useMemo(() => {
    if (!deliveryType) return [];

    const options = Object.values(optionsDictionary)
      .filter((o) => o.active)
      .filter((o) => (deliveryType ? o.deliveryTypes.indexOf(deliveryType) !== -1 : false))
      .filter((o) => (location ? o.cities.indexOf(location) !== -1 : true))
      .filter((o) => (containerType ? o.containerTypes.indexOf(containerType) !== -1 : true));

    return options.sort((a, b) => {
      if (a.value === "dangerous_cargo" || a.value === "genset_required") {
        return 1;
      }
      if (b.value === "dangerous_cargo" || b.value === "genset_required") {
        return -1;
      }

      if (a.value === b.value) return 0;
      if (a.value > b.value) return 1;

      return 0;
    });
  }, [deliveryType, location, containerType]);
}

export function useCustomerOrderStatuses() {
  const optionsDictionary = useOrderStatuses();

  return useMemo(() => {
    if (optionsDictionary.loading) return [];

    return Object.values(optionsDictionary.data).filter((item) => /customer$/i.test(item.value));
  }, [optionsDictionary.loading, optionsDictionary.data]);
}

type OrderConfigParamsType = {
  deliveryType?: string;
  containerType?: string;
  pickupCity?: string;
  deliveryCity?: string;
};
type OrderConfigResultType = {
  isContainerClassRequired: boolean;
  isHowToCarryRequired: boolean;
  isWarehouseAddressRequired: boolean;
  pickupTerminals: TerminalEntity[];
  deliveryTerminals: TerminalEntity[];
  howToCarryOptions: DirectoryRecord[];
};

export function useOrderConfig({
  deliveryType,
  containerType,
  pickupCity,
  deliveryCity,
}: OrderConfigParamsType): OrderConfigResultType {
  // pickup terminals
  const pickupTerminals = selectActiveOptions(useOrderPickupTerminals(deliveryType, pickupCity));
  // терминалы сдачи
  const deliveryTerminals = selectActiveOptions(useOrderDeliveryTerminals(deliveryType, deliveryCity));
  // как везти
  const howToCarryOptions = selectActiveOptions(useOrderHowToCarryOptions(containerType));

  return {
    isContainerClassRequired: containerType === containerTypes["45f"] || containerType === containerTypes["45ref"],
    isWarehouseAddressRequired: deliveryType !== EMPTY_CONTAINER,
    isHowToCarryRequired: howToCarryOptions.length > 0,
    pickupTerminals,
    deliveryTerminals,
    howToCarryOptions,
  };
}

export function useActiveDeliveryTypes(): DirectoryRecord[] {
  const deliveryTypes = useDeliveryTypes();
  return useMemo(() => {
    return selectActiveOptions(Object.values(deliveryTypes));
  }, [deliveryTypes]);
}

export function useActiveContainerTypes(): DirectoryRecord[] {
  const containerTypes = useContainerTypes();
  return useMemo(() => {
    return selectActiveOptions(Object.values(containerTypes));
  }, [containerTypes]);
}

export function useActiveContainerHeights(): DirectoryRecord[] {
  const containerHeightDictionary = useContainerHeights();
  return useMemo(() => {
    return selectActiveOptions(Object.values(containerHeightDictionary));
  }, [containerHeightDictionary]);
}

export function useActiveContainerClasses(): DirectoryRecord[] {
  const containerClassesDictionary = useContainerClasses();
  return useMemo(() => {
    return selectActiveOptions(Object.values(containerClassesDictionary));
  }, [containerClassesDictionary]);
}

export function useActiveLocations(): DirectoryRecord[] {
  const locationsDictionary = useLocations();
  return useMemo(() => {
    return selectActiveOptions(Object.values(locationsDictionary));
  }, [locationsDictionary]);
}

export function useActivePaymentTypes(): DirectoryRecord[] {
  const paymentTypes = usePaymentDictionary();
  return useMemo(() => {
    return selectActiveOptions(Object.values(paymentTypes));
  }, [paymentTypes]);
}

export function useActiveOrderStatuses(): DirectoryRecord[] {
  const orderStatuses = useCustomerOrderStatuses();
  return selectActiveOptions(orderStatuses);
}
