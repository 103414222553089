import Section from "@/components/Section2";
import { LandingEntity } from "@/domain";
import clsx from "clsx";
import { useWindowSize } from "react-use";
import LandingSectionTitle from "src/landing/components/SectionTitle";

import ExpandableAdvantage from "./ExpandableAdvantage";
import { useCallback, useState } from "react";
import { breakpoints } from "@/styles/constants";

const Advantage = ({ title, description, order }: { title: string; description: string; order: number }) => (
  <div className={clsx("flex flex-col gap-4")}>
    <div className="mb-2 h-1 w-30 bg-primary" />
    <div className="text-textMediumSemi ">{title}</div>
    <div className="text-textBody text-label">{description}</div>
  </div>
);

type Props = {
  advantages: LandingEntity[];
};

const Advantages = ({ advantages }: Props) => {
  const { width } = useWindowSize();
  const [expandedItemId, setExpandedItemId] = useState<number | undefined>(advantages[0].id);
  const handleToggle: (id: number) => void = useCallback(
    (id) => {
      const newId = expandedItemId === id ? undefined : id;
      setExpandedItemId(() => newId);
    },
    [expandedItemId],
  );

  return (
    <Section data-testid="advantages-section">
      <div className="flex max-w-xxl flex-col gap-8 py-10 tablet:gap-10 tablet:py-12 desktop:gap-16 desktop:pb-15 desktop:pt-[72px]">
        <LandingSectionTitle>Преимущества работы с KUBER</LandingSectionTitle>
        <div className="grid grid-cols-1 gap-0 tablet:grid-cols-2 tablet:gap-x-8 tablet:gap-y-10 desktop:grid-cols-3 desktop:gap-y-16">
          {advantages.map((item, index) => {
            if (width < breakpoints.TABLET) {
              return (
                <ExpandableAdvantage
                  key={item.id}
                  data={item}
                  open={item.id === expandedItemId}
                  onToggle={handleToggle}
                  order={index + 1}
                />
              );
            } else {
              return <Advantage title={item.title} description={item.text} key={item.id} order={index + 1} />;
            }
          })}
        </div>
      </div>
    </Section>
  );
};

export default Advantages;
