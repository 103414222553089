// TODO: typings
const MenuList = (props: any) => (
  <div
    ref={props.innerRef}
    {...props.innerProps}
    className="relative flex max-h-80 flex-col overflow-y-auto rounded-xl border border-bg bg-white py-2"
  >
    {props.children}
  </div>
);

export default MenuList;
