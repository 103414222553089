import React, { useState } from "react";
import clsx from "clsx";

import Button from "@/components/Button2";
import Modal from "@/components/Modal2";
import { LandingEntity, LandingImageEntity } from "@/domain";
import Arrow from "@/components/icons/Arrow";
import Section from "@/components/Section2";
import CloseIcon from "src/icons/CloseIcon";

interface CardProps {
  title: string;
  text: string;
  image: LandingImageEntity | null;
}

const Card: React.FC<CardProps> = ({ title, text, image }) => {
  const [open, setOpened] = useState(false);

  return (
    <>
      <div className={clsx("rounded-[40px]", "flex flex-row justify-between border border-divider desktop:w-1/2")}>
        <div className="flex flex-col justify-between gap-5 p-8 tablet:p-10 tablet:pr-0">
          <div>
            {title.split(" ").map((item: string) => (
              <h5 key={item} className="text-smallTitle tablet:text-title1">
                {item}
              </h5>
            ))}
          </div>
          <Button variant="link" onClick={() => setOpened(true)}>
            <div className="flex items-center space-x-1">
              <span className="self-stretch text-textBodySemi underline underline-offset-[3px]">Подробнее</span>
              <span className="flex h-4 w-4">
                <Arrow />
              </span>
            </div>
          </Button>
        </div>

        {image && <img src={image.link} className="hidden rounded-[40px] tablet:block" alt={image.name} />}
      </div>
      <Modal open={open} onClose={() => setOpened(false)}>
        <div className="flex flex-col gap-5">
          <div className="flex w-full flex-row items-start justify-between gap-5">
            <h3 className="text-title1">{title}</h3>
            <div className="cursor-pointer" onClick={() => setOpened(false)}>
              <CloseIcon />
            </div>
          </div>

          <div className="space-y-5" dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </Modal>
    </>
  );
};

interface Props {
  data: LandingEntity[];
}

const CargoOwnersAndMovers: React.FC<Props> = ({ data }) => (
  <Section data-testid="cargo-section">
    <div className="flex w-full max-w-xxl flex-col gap-8 py-10 tablet:py-12 desktop:flex-row desktop:py-15">
      {data.map((item) => (
        <Card title={item.title} key={item.id} text={item.text} image={item.image} />
      ))}
    </div>
  </Section>
);

export default React.memo(CargoOwnersAndMovers);
