import clsx from "clsx";

import Chevron from "@/components/icons/Chevron";

// TODO: typings
const DropdownIndicator = (props: any) => (
  <div className={clsx("flex-shrink-0 transition-all", props.selectProps.menuIsOpen && "!rotate-180")}>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M8.5 10.5L12.5 14.5L16.5 10.5"
        stroke="#555F6D"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default DropdownIndicator;
