import clsx from "clsx";
import React from "react";

export const SingleValue = (props: any) => {
  return (
    <span
      className={clsx(
        "w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-textBodySemi",
        "col-start-1 col-end-3 row-start-1 row-end-2 max-w-full",
      )}
      title={props.selectProps.getOptionLabel(props.data)}
    >
      {props.selectProps.getOptionLabel(props.data)}
    </span>
  );
};

export const UserSingleValue = (props: any) => {
  return (
    <div
      className={clsx(
        "w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-textBodySemi",
        "col-start-1 col-end-3 row-start-1 row-end-2 mb-2 max-w-full",
      )}
    >
      <span title={props.selectProps.getOptionLabel(props.data)}>
        {props.selectProps.getOptionLabel(props.data)}&nbsp;({props.data.prefix})
      </span>
      <br />
      <span className="mb-2 -translate-y-2 text-secondaryText/50">
        {props.data.company_name}&nbsp;({props.data.company_inn})
      </span>
    </div>
  );
};

export const UserWithRoleSingleValue = (props: any) => {
  return (
    <div
      className={clsx(
        "w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-textBodySemi",
        "col-start-1 col-end-3 row-start-1 row-end-2 mb-2 max-w-full",
      )}
    >
      <span title={props.selectProps.getOptionLabel(props.data)}>
        {props.selectProps.getOptionLabel(props.data)}&nbsp;({props.data.prefix})
      </span>
      <br />
      <span className="mb-2 -translate-y-2 text-secondaryText/50">
        {props.data.company_name}&nbsp;({props.data.company_inn})
      </span>
    </div>
  );
};
