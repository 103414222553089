import clsx from "clsx";

interface Props extends React.HTMLAttributes<HTMLFieldSetElement> {
  legend: string;
  className?: string;
}

const FieldSet: React.FC<Props> = ({ legend, className, children, ...props }) => {
  return (
    <fieldset className={clsx("border-t border-line", className)} {...props}>
      <legend className="pr-6 text-textBody text-label">{legend}</legend>
      {children}
    </fieldset>
  );
};

export default FieldSet;
