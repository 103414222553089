import React, { MouseEventHandler, useCallback } from "react";
import clsx from "clsx";
import { LandingEntity } from "@/domain";
import Chevron2 from "@/components/icons/Chevron/Chevron2";

interface Props {
  data: LandingEntity;
  open: boolean;
  onToggle: (id: number) => void;
  order: number;
}

const ExpandableAdvantage: React.FC<Props> = ({ data, open, onToggle, order }) => {
  const handleToggle: MouseEventHandler<HTMLDetailsElement> = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onToggle(data.id);
    },
    [onToggle, data.id],
  );

  return (
    <details className={clsx("w-full border-t border-divider py-4 last:border-b")} open={open} onClick={handleToggle}>
      <summary className="flex cursor-pointer flex-row items-center justify-between gap-2">
        <span className="text-textBodySemi">{data.title}</span>
        {!open ? (
          <div className="text-primary">
            <Chevron2 />
          </div>
        ) : (
          <div className="rotate-180 text-primary">
            <Chevron2 />
          </div>
        )}
      </summary>
      <div id="faq-item" className={clsx("pt-5 text-textSmall text-label")}>
        {data.text}
      </div>
    </details>
  );
};

export default React.memo(ExpandableAdvantage);
