import { GetStaticProps, NextPage } from "next";
import Head from "next/head";

import Hero from "@/sections/Hero";
import Advantages from "@/sections/Advantages";
import HowItWorks from "@/sections/HowItWorks";
import Features from "@/sections/Features";
import HowToGetStarted from "@/sections/HowToGetStarted";
import Calculator from "@/sections/Calculator";
import Faq from "@/sections/Faq";
import Reviews from "@/sections/Reviews";
import Media from "@/sections/Media";

import { fetchMainPageData, fetchNews } from "@/api/ssg-api";
import { LandingEntity, LandingNewsEntity } from "@/domain";
import CargoOwnersAndMovers from "@/sections/Hero/CargoOwnersAndMovers";

interface Props {
  info: LandingEntity;
  subinfo: LandingEntity[];
  howToGetStartedSteps: LandingEntity[];
  news: LandingNewsEntity[];
  faq: LandingEntity[];
  reviews: LandingEntity[];
  scheme: LandingEntity[];
  advantages: LandingEntity[];
  features: LandingEntity[];
}

const HomePage: NextPage<Props> = ({
  info,
  subinfo,
  advantages,
  news,
  howToGetStartedSteps,
  faq,
  reviews,
  scheme,
  features,
}) => {
  return (
    <>
      <Head>
        <title>KUBER Автоперевозка контейнеров</title>
      </Head>
      <Hero info={info} />
      <img src="/images/hero/mobile.png" width="100%" className="object-cover tablet:hidden" alt="hero_mobile" />

      {/* Преимущества */}
      <Advantages advantages={advantages} />

      <CargoOwnersAndMovers data={subinfo} />

      <Features features={features} />

      {/* Расчёт тарифа */}
      <Calculator />

      <HowItWorks scheme={scheme} />

      {/* Отзывы */}
      {reviews.length > 0 && <Reviews reviews={reviews} />}

      {/* Как начать пользоваться */}
      <HowToGetStarted steps={howToGetStartedSteps} />

      {/* Часто задаваемые вопросы */}
      {faq.length > 0 && <Faq data={faq} />}

      {/* Новости на главной */}
      {news.length > 0 && <Media news={news} />}
    </>
  );
};

export default HomePage;

export const getStaticProps: GetStaticProps<Props> = async () => {
  const responses = await Promise.all([fetchNews({ limit: 3, offset: 0 }), fetchMainPageData()]);
  const newsData = responses[0];
  const pageData = responses[1];

  return {
    props: {
      info: pageData.mainInfo,
      subinfo: pageData.simpleText,
      advantages: pageData.advantages,
      howToGetStartedSteps: pageData.howToUse,
      news: newsData.items,
      faq: pageData.questions,
      reviews: pageData.reviews,
      scheme: pageData.workScheme,
      features: pageData.features,
    },
  };
};
