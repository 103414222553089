import CloseIcon from "src/icons/CloseIcon";
import { components } from "react-select";

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator className="!cursor-pointer !p-0" {...props}>
      <CloseIcon />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
